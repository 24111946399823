import Vue from "vue";
import Router from "vue-router";
import { Role } from "./helpers/role";
import store from "./store/store";

Vue.use(Router);

// Check the user's auth status when the app starts
//auth.checkAuth()

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      meta: {
        requiresAuth: true
      },
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        // {
        //   path: '/', redirect: '/'
        //   // path: '/',
        //   // name: 'home',
        //   // component: () => import('./views/tasks/TaskList.vue'),
        //   // meta: {
        //   //   requiresAuth: true
        //   //}
        // },

        {
          path: "/",
          meta: { authorize: [] }
        },

        // NEW ROUTER LINKS. OLD ROUTES REMOVED 15.08.2022
        {
          path: "/employees",
          name: "employees",
          component: () => import("./views/employees/EmployeeList.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin, Role.Opretter]);
            next(auth);
          }
        },
        {
          path: "/employees/:employeeId?",
          name: "employee",
          props: true,
          component: () => import("./views/employees/RegisterEmployee.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin, Role.Opretter]);
            next(auth);
          }
        },
        {
          path: "/employees/register",
          name: "register-employee",
          props: true,
          component: () => import("./views/employees/RegisterEmployee.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin, Role.Opretter]);
            next(auth);
          }
        },
        {
          path: "/tasks",
          name: "tasks",
          component: () => import("./views/tasks/TaskList.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin, Role.Jura, Role.HR, Role.Løn, Role.IT]);
            next(auth);
          }
        },
        {
          path: "/tasks/:employeeId?",
          name: "task",
          props: true,
          component: () => import("./views/tasks/Task.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin, Role.Jura, Role.HR, Role.Løn, Role.IT]);
            next(auth);
          }
        },
        {
          path: "/profiles",
          name: "profiles",
          component: () => import("./views/profiles/ProfileList.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin]);
            next(auth);
          }
        },
        {
          path: "/profiles/:profileId?",
          name: "profile",
          props: true,
          component: () => import("./views/profiles/RegisterProfile.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin]);
            next(auth);
          }
        },
        {
          path: "/functions",
          name: "functions",
          component: () => import("./views/functionsgroup/FunctionsGroupList.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin]);
            next(auth);
          }
        },
        {
          path: "/functions/:functionId?",
          name: "function",
          props: true,
          component: () => import("./views/functionsgroup/RegisterFunctionGroup.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin]);
            next(auth);
          }
        },
        
        {
          path: "/hardwares",
          name: "hardwares",
          component: () => import("./views/hardwares/HardwareList.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin]);
            next(auth);
          }
        },
        {
          path: "/hardwares/:hardwareId?",
          name: "hardware",
          props: true,
          component: () => import("./views/hardwares/RegisterHardware.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin]);
            next(auth);
          }
        },
        {
          path: "/hardwareOptions",
          name: "hardwareOptions",
          component: () => import("./views/hardwareOptions/HardwareOptionList.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin]);
            next(auth);
          }
        },
        {
          path: "/hardwareOptions/:optionId?",
          name: "hardwareOption",
          props: true,
          component: () => import("./views/hardwareOptions/RegisterHardwareOption.vue"),
          beforeEnter: (to, from, next) => {
            var auth = checkAuth([Role.Admin]);
            next(auth);
          }
        },





      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue")
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading // Denne er fjernet, og indsat i TheNavbarVertical og udføres når roller er udfyldt.
  // const appLoading = document.getElementById("loading-bg");
  // if (appLoading) {
  //   appLoading.style.display = "none";
  // }
});

router.beforeResolve(async (to, from, next) => {
  // No auth required
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    next();
    return;
  }

  // Authenticated
  var accessToken = localStorage.getItem("identity.accessToken");
  if (accessToken !== null /*&& router.app.$msal.isAuthenticated() === true*/) {
    next();
    return;
  }
});

function checkAuth(requiredRoles) {
  let currentUser = null;

  if (Vue.prototype.$AuthService.isAuthenticated()) {
    // Note that the dollar sign ($) is missing from this.msal
    currentUser = {
      ...Vue.prototype.$AuthService.getUser(),
      profile: {}
    };
  }

  if (currentUser && requiredRoles.some(r => store.state.roles.includes(r))) {
    return true;
  } else {
    return false;
  }
}

export default router;
