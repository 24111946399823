/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "./../axios.js";
import { Role } from "../helpers/role";

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit("UPDATE_VERTICAL_NAV_MENU_WIDTH", width);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list);
  },

  ///////////////////////////////////////////////
  // UI
  ///////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },

  ///////////////////////////////////////////////
  // User/Account
  ///////////////////////////////////////////////

  login({ commit }, user) {
    alert("actions.js:login");
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: "https://localhost:44331/api/sessions",
        data: user,
        method: "POST"
      })
        .then(resp => {
          const token = "Bearer " + resp.data.token;
          //const user = resp.data.user
          localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = token;
          commit("auth_success", token);
          resolve(resp);
        })
        .catch(err => {
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve /*, reject*/) => {
      commit("logout");
      localStorage.removeItem("token");
      console.log("DELETING TOKEN");
      delete axios.defaults.headers.common["Authorization"];
      resolve();
    });
  },

  fetchUserDataAction({ commit }) {
    
    const accessToken = localStorage.getItem("identity.accessToken");

    console.log("actions.js:fetchUserDataAction: Token=" + (accessToken ? "True" :" false"));

    console.log(process.env.VUE_APP_API + "/Users/userData");

    axios
      .get(process.env.VUE_APP_API + "/Users/userData", {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then(res => {
        if (res && res.data) {
          var roles = [];
          // Admin rollen får også HR, Løn, Jura, IT. Skal måske styres af en 'test' parameter.
          if (res.data.isAdmin) {
            roles.push(Role.Admin);
          }
          if (res.data.isModifier) {
            roles.push(Role.Opretter);
          }
          if (res.data.isJura || res.data.isAdmin) {
            roles.push(Role.Jura);
          }
          if (res.data.isHR || res.data.isAdmin) {
            roles.push(Role.HR);
          }
          if (res.data.isLoen || res.data.isAdmin) {
            roles.push(Role.Løn);
          }
          if (res.data.isIT || res.data.isAdmin) {
            roles.push(Role.IT);
          }

          if (roles.length == 0) {
            roles.push(Role.Guest);
          }

  //  roles = [];
  //  roles.push(Role.Opretter);
  //  roles.push(Role.IT);

          commit("SET_ROLES", { roles });

          //if (res.data) {
          var userData = res.data;
          commit("SET_USERDATA", { userData });
        }
      })
      .catch(error => console.log(error));
  }

  ///////////////////////////////////////////////
  // Employee
  ///////////////////////////////////////////////
};

export default actions;
