

<template>
      <vs-button
        @click="$router.go(-1)"
        id="back-button"
        class="ml-auto mr-4 mt-2"
        size="medium"
      >
        Tilbage
      </vs-button>
    
</template>

<script>
export default {
    name: "backbutton"
}
</script>