// Enter here the user flows and custom policies for your B2C application
// To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
// To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview

export let b2cPolicies = {
    names: {
        signIn: process.env.VUE_APP_AD_B2C_POLICY_SIGNIN,
        resetPassword: process.env.VUE_APP_AD_B2C_POLICY_RESET_PASSWORD
    },
    authorities: {
        signIn: {
            authority: process.env.VUE_APP_AD_B2C_AUTHORITY + process.env.VUE_APP_AD_B2C_POLICY_SIGNIN,
        },
        resetPassword: {
            authority: process.env.VUE_APP_AD_B2C_AUTHORITY + process.env.VUE_APP_AD_B2C_POLICY_RESET_PASSWORD,
        },
    },
}