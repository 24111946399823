import * as Msal from "msal";
import * as jwt_decode from "jwt-decode";

import { msalConfig, loginRequest, tokenRequest } from "./msal/authConfig";
import { b2cPolicies } from "./msal/policies";

export default class AuthService {
  constructor(onAuthenticated) {
    this.onAuthenticated = onAuthenticated;

    this.msalObj = new Msal.UserAgentApplication(msalConfig);

    // Register Callbacks for Redirect flow
    let ctx = this;
    this.msalObj.handleRedirectCallback((error, response) =>
      this.authRedirectCallBack(ctx, error, response)
    );
  }

  authRedirectCallBack(ctx, error, response) {
    console.log("index:authRedirectCallBack");
    // Error handling
    if (error) {
      console.log(error);

      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        try {
          localStorage.setItem("identity.isResettingPassword", true);

          this.msalObj.loginRedirect(b2cPolicies.authorities.resetPassword); // Password reset policy/authority
        } catch (err) {
          localStorage.setItem("identity.isResettingPassword", false);

          console.log(err);
        }
      } else if (error.errorMessage.indexOf("AADB2C90091") > -1) {
        localStorage.setItem("identity.isResettingPassword", false);
      } else {
        console.log(response);
        alert(error.errorMessage);
      }
    } else {
      // We need to reject id tokens that were not issued with the default sign-in policy.
      if (
        response.tokenType === "id_token" &&
        response.idToken.claims["tfp"] === b2cPolicies.names.resetPassword
      ) {
        localStorage.setItem("identity.isResettingPassword", false);
        this.logout();
        console.log(
          "Password has been reset successfully. \nPlease sign-in with your new password."
        );
      } else if (
        response.tokenType === "id_token" &&
        response.idToken.claims["tfp"] === b2cPolicies.names.signIn
      ) {
        console.log("id_token acquired at: " + new Date().toString());
        //alert('Got id_token from sign in policy');

        /*if (this.msalObj.getAccount()) {
                //updateUI();
                }*/
        //ctx.getTokenRedirect();
      } else if (response.tokenType === "access_token") {
        console.log("authRedirectCallBack:", response);

        this.onAuthenticated(response);
      } else {
        console.log("Token type is: " + response.tokenType);
      }
    }
  }

  isAuthenticated() {
    console.log("index:isAuthenticated");
    var account = this.msalObj.getAccount();
    var accessToken = localStorage.getItem("identity.accessToken");

    // console.log(accessToken);

    if (account && accessToken) {
      return true;
    }

    return false;
  }

  // getAccount() {
  //   console.log("index:getAccount");
  //   return this.msalObj.getAccount();
  // }

  login(redirectUrl) {
    console.log("index:login");
    localStorage.setItem("identity.redirectUrl", redirectUrl);

    const isResettingPassword = localStorage.getItem("identity.isResettingPassword");
    
    if (isResettingPassword === "true") {
      localStorage.setItem("identity.isResettingPassword", false);
      return;
    }
    console.log("index:login :: Logged in=" + (this.msalObj.getAccount()?"True":"False"));
    // if the user is already logged in you can acquire a token
    if (this.msalObj.getAccount()) {
      this.acquireTokenSilent();
    } else {
      // user is not logged in, you will need to log them in to acquire a token
      //this.loginPopup();
      this.loginRedirect();
    }
  }

  logout() {
    console.log("index:logout");
    
    localStorage.clear();
    // Removes all sessions, need to call AAD endpoint to do full logout
    this.msalObj.logout();
    
  }

  loginPopup() {
    console.log("index:loginPopup");
    this.msalObj
      .loginPopup(loginRequest)
      .then(response => {
        // get access token from response
        // response.accessToken
        console.log("loginPopup:", response);

        if (response.tokenType === "id_token") {
          console.log("Got id_token");

          this.acquireTokenSilent();
        }
      })
      .catch(err => {
        console.log("Error in loginPopup:");
        console.log(err);
      });
  }

  loginRedirect() {
    console.log("index:loginRedirect");
    console.log(loginRequest);
    this.msalObj.loginRedirect(loginRequest);
  }

  acquireTokenSilent() {
    console.log("index:acquireTokenSilent");
    this.msalObj
      .acquireTokenSilent(tokenRequest)
      .then(response => {
        // get access token from response
        // response.accessToken
        console.log("acquireTokenSilent: " + response.tokenType);

        if (response.tokenType === "access_token") {
          this.onAuthenticated(response);
        }
      })
      .catch(err => {
        console.log("acquireTokenSilent: err= " + err.name);

        // could also check if err instance of InteractionRequiredAuthError if you can import the class.
        if (err.name === "InteractionRequiredAuthError") {
          return this.msalObj
            .acquireTokenPopup(tokenRequest)
            .then(response => {
              console.log("acquireTokenPopup:", response);

              if (response.tokenType === "access_token") {
                this.onAuthenticated(response);
              }
            })
            .catch(err => {
              // handle error
              console.log("Error in acquireTokenPopup:");
              console.log(err);
            });
        }
      });
  }

   async getUser() {
    console.log("index:getUser");
    var accessToken = localStorage.getItem("identity.accessToken");
    var decoded = jwt_decode(accessToken);

    // var roles = [];

    return {
      name: this.msalObj.account.name,
      // role: roles,
      type: decoded.extension_UserType
    };
  }

  getRoles() {
    let currentUser = null;
    console.log("index:getRoles");

    if (this.isAuthenticated()) {
      // Note that the dollar sign ($) is missing from this.msal
      currentUser = {
        ...this.getUser(),
        profile: {}
      };
    }
    return currentUser.role;
  }
}
