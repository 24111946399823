/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import moment from 'moment';
import router from './router'

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

//localStorage.setItem('authenticated', false);

Vue.filter('formattedRole', function (value) {
  if (!value) return 'Undefined role'
  if (value === 0) return 'Read Only';
  if (value === 1) return 'Standard';
  if (value === 2) return 'Admin';
  if (value === 3) return 'Owner';

  return 'Undefined role';
})

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('DD/MM/YYYY')
  }
});

import VueFileUploader from './plugins/VueFileUploader'
Vue.use(VueFileUploader)

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

// axios
import axios from "./axios.js"
Vue.prototype.$http = axios

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyBQl4BHWWfLbaRu5f_UQJUh77mJkPPNn9A',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import AuthService from './auth'
Vue.prototype.$AuthService = new AuthService((response) => {
  console.log("main.js:On Autenticated:", response);

  const accessToken = response.accessToken;
  //const userType = response.idTokenClaims.extension_UserType;

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
  localStorage.setItem('identity.accessToken', accessToken);

  var redirectUrl = localStorage.getItem('identity.redirectUrl');
  

  // Denne opdaterer roller og userdate i state. Lå oprindeligt i App.vue, men blev vist 'kaldt' for tidligt.
  store.dispatch("fetchUserDataAction");

  router.push({ path: redirectUrl.substring(1) }).catch(() => {})
})

// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
      Vue.prototype.$AuthService.login(location.pathname + location.search);
  } else {
      return Promise.reject(error);
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

if (location.pathname !== '/auth-redirect') {
  Vue.prototype.$AuthService.login(location.pathname + location.search);
}
