/**
 * Config object to be passed to MSAL on creation.
 * For a full list of msal.js configuration parameters, 
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 * */

 import { b2cPolicies } from './policies'
 import { apiConfig } from './apiConfig'

 //console.log('Authority:', b2cPolicies.authorities.signIn.authority)
 
 export let msalConfig = {
    auth: {
      clientId: process.env.VUE_APP_AD_B2C_CLIENT_ID,
      authority: b2cPolicies.authorities.signIn.authority,
      validateAuthority: false,
      redirectUri: process.env.VUE_APP_AD_B2C_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
    }
  };
  
  /** 
   * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
   * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
   */
  export let loginRequest = {
    //scopes: ["openid", "profile"],
    scopes: [
      'https://domeaiam.onmicrosoft.com/identity-api/api'
    ]
  };
  
  // Add here scopes for access token to be used at the API endpoints.
  export let tokenRequest = {
    scopes: apiConfig.b2cScopes,  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  };